@import "../../App.scss";


.lotto-control{
    width: 100vw;
    height: 100vh;
    background: $bg-color;
    display: flex;
    padding: 12px;

    .lotto-item-control {
        display: flex;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        max-width: 772px;
        flex-direction: column;

        .lotto-current-control{
            background-color: white;
            border-radius: 8px;
            border: none;
            padding: 14px;
            display: flex;
            flex-direction: column;

            .lotto-current-header{
                font-size: 18px;
                color: $text-black;
                font-family: $fontMedium;
                text-align: center;
            }
            .lotto-current-form-group{
                display: flex;
                flex-direction: column;
                margin-top: 24px;
                width: 100%;
            }
            .lotto-current-input-label{
                font-size: 14px;
                font-family: $fontMedium;
                color: $text-black;
                text-align: center;
                margin-left: auto;
                margin-right: auto;
            }

            .lotto-current-search-control{
                display: flex;
                width: 80%;
                margin-left: auto;
                margin-right: auto;

                .lott-current-search-button{
                    background-color: black;
                    color: white;
                    width: 30%;
                    border: none;
                    font-size: 14px;
                    font-family: $fontMedium;
                    margin-left: 8px;
                    
                }
            }
        }
        .lotto-current-success-control{
            margin-top: 8px;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            border: 2px solid $green-color;
            padding: 16px;
            .lotto-current-success-sub-header{
                font-size: 20px;
                color: $green-color;
                text-align: center;
                margin-left: auto;
                margin-right: auto;
                font-family: $fontRegular;
            }
            .lotto-current-success-header{
                font-size: 36px;
                color: $green-color;
                text-align: center;
                margin-left: auto;
                margin-right: auto;
                font-family: $fontMedium;
            }
        }
        .lotto-current-failed-control{
            margin-top: 8px;
            border-radius: 8px;
            background-color: #efbcbc;
            display: flex;
            flex-direction: column;
            padding: 16px;
            .lotto-current-failed-sub-header{
                font-size: 20px;
                color: $red;
                text-align: center;
                margin-left: auto;
                margin-right: auto;
                font-family: $fontRegular;
            }
            .lotto-current-failed-header{
                font-size: 24px;
                color: $red;
                text-align: center;
                margin-left: auto;
                margin-right: auto;
                font-family: $fontMedium;
            }
        }
        .lotto-current-show-all-control{
            border-radius: 8px;
            width: 100%;
            padding: 30px;
            background-color: $bg-color;
            display: flex;
            margin-top: 24px;
            border: none;
            flex-direction: column;

            .lotto-current-show-all-title{
                font-size: 18px;
                font-family: $fontRegular;
                text-align: center;
                color: white;
                margin-left: auto;
                margin-right: auto;
            }
           
            .lotto-current-show-all-value-1{
                font-size: 36px;
                font-family: $fontMedium;
                text-align: center;
                color: $button-color;
                margin-left: auto;
                margin-right: auto;
                margin-top: 12px;
            }

            .lotto-current-show-all-divider{
                background-color: white;
                width: 100%;
                height: 1px;
                margin-top: 16px;
                margin-bottom: 16px;
            }

            .lotto-current-show-all-running-control{
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                column-gap: 16px;

                .lotto-current-show-all-running-item{
                    display: flex;
                    flex-direction: column;
                }
            }

            .lotto-current-show-all-other-control{
                display: flex;
                flex-direction: column;
                .lotto-current-show-all-other-title{
                    font-size: 18px;
                    font-family: $fontMedium;
                    text-align: left;
                    color: white;
                }
                .lotto-current-show-all-other-sub-title{
                    font-size: 14px;
                    font-family: $fontRegular;
                    text-align: left;
                    color: white;
                }
                .lotto-current-show-all-other-value-control{
                    display: grid;
                    grid-template-columns: repeat(5, 1fr);
                    margin-top: 16px;

                    @include media-breakpoint-down(md){
                        grid-template-columns: repeat(3, 1fr);
                    }
                }
                .lotto-current-show-all-other-value{
                    font-size: 20px;
                    font-family: $fontMedium;
                    text-align: left;
                    color: $button-color;
                }
            }
        }


    }
}