@import "../../App.scss";


.menu-5-control {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 24px;


    .menu-5-playing-control {
        display: flex;
        flex-direction: column;
        max-width: 768px;
        margin-left: auto;
        margin-right: auto;

        @include media-breakpoint-down(md){
            max-width: auto;
            width: 100%;
        }


        .menu-5-playing-title {
            font-size: 18px;
            font-family: $fontMedium;
            color: white;
            margin-left: auto;
            margin-right: auto;
        }

        .menu-5-playing-desc {
            font-size: 13px;
            font-family: $fontRegular;
            color: white;
            line-height: 1.2em;
            margin-left: auto;
            text-align: center;
            margin-right: auto;
        }

        .menu-5-form-control {
            display: flex;
            flex-direction: column;
            text-align: left;
            min-height: 400px;
            margin-top: 60px;

            .menu-5-form-control-item{
                margin-bottom: 16px;
                display: flex;
                flex-direction: column;
            }
           

            .menu-5-form-loading {
                margin: auto;
                display: flex;
                flex-direction: column;

                .menu-5-form-loading-spinner {
                    width: 30px;
                    height: 30px;
                    object-fit: contain;
                    margin-left: auto;
                    margin-right: auto;
                    color: white;
                    margin-bottom: 14px;
                }

                .menu-5-form-loading-text {
                    font-size: 16px;
                    font-family: $fontMedium;
                    color: white;
                    margin-left: auto;
                    margin-right: auto;
                }
            }


            .menu-5-form-label {
                font-size: 16px;
                font-family: $fontMedium;
                color: white;
            }

            .menu-5-form-warning {
                font-size: 12px;
                font-family: $fontRegular;
                color: white;
                margin-top: 4px;
            }
            .menu-5-form-input {
                width: 100%;
                height: 46px;
                margin-top: 12px;
                font-size: 20px;
                font-family: $fontMedium;
                color: $text-black;
            }
            .menu-5-tarot-control {
                width: 100%;
                height: 200px;
                position: relative;
                display: block;
                justify-content: center;
            }
        }

        .menu-5-button {
            margin-top: auto;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            height: 48px;
            border: 1px solid $button-color !important;
            background-color: $button-color !important;
            color: $primary-color !important;
            font-size: 16px;
            font-family: $fontMedium;
        }
    }

}