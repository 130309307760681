@import "../../App.scss";

.tarot-card-button {
    margin-top: 36px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    height: 48px;
    border: 1px solid $button-color !important;
    background-color: $button-color !important;
    color: $primary-color !important;
    font-size: 16px;
    font-family: $fontMedium;
  }

.tarot-card-img{
  width: 60px;
  height: 110px;
  aspect-ratio: 7/11;
  object-fit: contain;
}

.tarot-card-img-active{
  width: 60px;
  height: 110px;
  border: 2px solid $button-color;
  border-radius: 8px;
  aspect-ratio: 7/11;
  object-fit: contain;
}